import React from "react"
import { Link, graphql } from "gatsby"
//import { Row, Col, Button } from 'react-bootstrap'
import Layoutpost from "../components/layoutpost"
import SEO from "../components/seo"
import { SlugLink } from '../components/utils'
//import Img from "gatsby-image"
//import { rhythm, scale } from "../utils/typography"
//import Sidebar from '../components/Sidebar'
//import { LazyLoadImage } from 'react-lazy-load-image-component';
//import 'react-lazy-load-image-component/src/effects/blur.css';
//import avatar from "../components/avatar.png"


class BlogPostTemplate extends React.Component {
  componentDidMount() {
    var currentUrl = window.location.pathname;
    if(window.location.pathname.endsWith("/")){
      console.log(currentUrl.slice(0,currentUrl.length - 1));
      window.location.href = currentUrl.slice(0,currentUrl.length - 1);
    }
  }

  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
  //  const { previous, next } = this.props.pageContext
    //console.log(post)
    //console.log(next);
    return (
      <Layoutpost location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <div className="col-lg-8 p1050">
          <article className="sppa">
            <header className="arhd">
              <h1 className="arhl">{post.frontmatter.title}</h1>
            </header>

            <div className="tdpc" dangerouslySetInnerHTML={{ __html: post.html }} />
            <div className="plo">
              <time>Published {post.frontmatter.date}</time>
            </div>
            <div className="tagsc2">
              {post.frontmatter.tags !== null ?
                post.frontmatter.tags.map(tag => (
                  <Link to={`/tags/${SlugLink(tag)}`} key={tag}>{tag}</Link>
                ))
                :
                null
              }
            </div>

          </article>

        </div>
      </Layoutpost>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        category
        tags
        cover {
          publicURL
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
