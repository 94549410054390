import React from "react"
import { Link } from "gatsby"
import logo from './streamlineforex.png'

class Sidebar extends React.Component {
  render() {
    return (
      <>
        
        <div className="col-lg-4 col-md-12 sidenav">
                <div className="sidebar sticky-top sdbr">
                <span>
                  <Link className="nava_logo" to="/">
                   <img className="nav_logo_img" src={logo} alt="CAS Trader" />
                  </Link>
                </span>
                    <div className="listmn">
                        {/* <a href="/">Artigos</a> */}
                        {/* <a href="/about">Sobre nós</a>
                        <a href="/politica-de-privacidade">Política de Privacidade</a>
                        <a href="/informacao-de-riscos">Informações De Risco</a> */}
                    </div>
                </div>
        </div>
      </>
    )
  }
}

export default Sidebar